var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-3 ma-4",attrs:{"headers":_vm.headers,"items":_vm.datas,"page":_vm.data_table_options.page,"server-items-length":_vm.data_table_options.totalItems,"options":_vm.data_table_options,"footer-props":_vm.data_table_options.footerProps,"mobile-breakpoint":"1200","hide-default-footer":""},on:{"pagination":function (item) { return _vm.handlePage(item); },"click:row":function (item) { return _vm.$emit('edititem', item); }},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('img',{staticStyle:{"width":"65px","height":"70px","margin":"10px","border-radius":"50px"},attrs:{"src":_vm.checkAvatar(item),"width":"50px"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.permissionCheck('modify'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('edititem', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.edit")))])]):_vm._e(),(_vm.permissionCheck('del'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('deleteitem', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.delete")))])]):_vm._e()]}},{key:"item.note",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkStrLength(item.note))+" "),(item.note.length > 30)?_c('span',{staticClass:"blue--text"},[_vm._v(".....more")]):_vm._e()]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkStrLength(item.address))+" "),(item.address.length > 30)?_c('span',{staticClass:"blue--text"},[_vm._v(".....more ")]):_vm._e()]}}],null,true)}),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"text-center mt-4"},[_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"4"}},[_c('v-pagination',{attrs:{"length":_vm.data_table_options.totalItems},model:{value:(_vm.data_table_options.page),callback:function ($$v) {_vm.$set(_vm.data_table_options, "page", $$v)},expression:"data_table_options.page"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"1"}},[_c('v-text-field',{staticClass:"noUnderline",attrs:{"readonly":"","label":"Total Pages","value":_vm.data_table_options.totalItems,"disabled":""}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"min":"1","label":"Jump to page","type":"number","hint":"Press Enter or Unfocus to Confirm"},on:{"focus":function($event){return $event.target.select()},"change":_vm.handleGo2Page}})],1)],1)],1):_c('div',{staticClass:"text-center mt-4"},[_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"8"}},[_c('v-pagination',{attrs:{"length":_vm.data_table_options.totalItems},model:{value:(_vm.data_table_options.page),callback:function ($$v) {_vm.$set(_vm.data_table_options, "page", $$v)},expression:"data_table_options.page"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"noUnderline",attrs:{"readonly":"","label":"Total Pages","value":_vm.data_table_options.totalItems,"disabled":""}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"min":"1","label":"Jump to page","type":"number","hint":"Press Enter or Unfocus to Confirm"},on:{"focus":function($event){return $event.target.select()},"change":_vm.handleGo2Page}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }