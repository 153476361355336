<template>
  <div>
    <v-data-table
      class="elevation-3 ma-4"
      :headers="headers"
      :items="datas"
      :page="data_table_options.page"
      :server-items-length="data_table_options.totalItems"
      :options="data_table_options"
      :footer-props="data_table_options.footerProps"
      mobile-breakpoint="1200"
      hide-default-footer
      @pagination="(item) => handlePage(item)"
      @click:row="(item) => $emit('edititem', item)"
    >
      <template v-slot:[`item.image`]="{ item }">
        <img
          :src="checkAvatar(item)"
          width="50px"
          style="width: 65px; height: 70px; margin: 10px; border-radius: 50px"
        />
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top v-if="permissionCheck('modify')">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              color="green"
              @click.stop="$emit('edititem', item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.edit") }}</span>
        </v-tooltip>

        <v-tooltip top v-if="permissionCheck('del')">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click.stop="$emit('deleteitem', item)"
              color="red"
              v-bind="attrs"
              v-on="on"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.delete") }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.note`]="{ item }">
        {{ checkStrLength(item.note) }}
        <span class="blue--text" v-if="item.note.length > 30">.....more</span>
      </template>

      <template v-slot:[`item.address`]="{ item }">
        {{ checkStrLength(item.address) }}
        <span class="blue--text" v-if="item.address.length > 30"
          >.....more
        </span>
      </template>
    </v-data-table>

    <div class="text-center mt-4" v-if="$vuetify.breakpoint.mdAndUp">
      <v-row>
        <v-spacer />

        <v-col cols="4" class="mt-2">
          <v-pagination
            v-model="data_table_options.page"
            :length="data_table_options.totalItems"
          />
        </v-col>

        <v-spacer />

        <v-col cols="1" class="d-flex justify-center align-center">
          <v-text-field
            readonly
            label="Total Pages"
            :value="data_table_options.totalItems"
            class="noUnderline"
            disabled
          />
        </v-col>

        <v-col cols="2">
          <v-text-field
            min="1"
            label="Jump to page"
            type="number"
            hint="Press Enter or Unfocus to Confirm"
            @focus="$event.target.select()"
            @change="handleGo2Page"
          />
        </v-col>
      </v-row>
    </div>

    <div class="text-center mt-4" v-else>
      <v-row>
        <v-spacer />

        <v-col cols="8" class="mt-2">
          <v-pagination
            v-model="data_table_options.page"
            :length="data_table_options.totalItems"
          />
        </v-col>

        <v-spacer />

        <v-col cols="6" class="d-flex justify-center align-center">
          <v-text-field
            readonly
            label="Total Pages"
            :value="data_table_options.totalItems"
            class="noUnderline"
            disabled
          />
        </v-col>

        <v-col cols="6">
          <v-text-field
            min="1"
            label="Jump to page"
            type="number"
            hint="Press Enter or Unfocus to Confirm"
            @focus="$event.target.select()"
            @change="handleGo2Page"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import serverConfig from "@/utils/serverConfig";

export default {
  name: "ProductTable",
  props: {
    dialog: Boolean,
    datas: Array,
    search: String,
    data_table_options: Object,
    headers: Array,
  },
  data() {
    return {
      tempBlank: serverConfig.blank_img,
      blankIMG: serverConfig.blank_profile_img,
      imgUrl: serverConfig.img_url,
    };
  },
  methods: {
    handlePage(item) {
      this.$emit("pageChange", item);
    },
    handleGo2Page(item) {
      this.$emit("go2Page", Number(item));
    },
    checkAvatar(item) {
      return typeof item.image === "string"
        ? item.image == ""
          ? this.tempBlank
          : this.imgUrl + item.image
        : item.image[0] != ""
        ? this.imgUrl + item.image[0]
        : this.tempBlank;
    },
    checkStrLength(item) {
      if (item.length > 30) {
        return item.substring(0, 30);
      }
    },
  },
};
</script>